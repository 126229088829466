const fadeInOnScrollFrom = (elem, direction) => {
    direction = direction | 1;

    let x = 0;
    let y = direction * 100;

    if (elem.classList.contains("revealFromLeft")) {
        x = -100;
        y = 0;
    } else if (elem.classList.contains("revealFromRight")) {
        x = 100;
        y = 0;
    }

    gsap.fromTo(elem, {x: x, y: y, autoAlpha: 0}, {
        duration: 2,
        delay: 1,
        x: 0,
        y: 0,
        autoAlpha: 1,
        ease: "expo",
        overwrite: "auto"
    });
}

const revealFromSide = () => {
    gsap.utils.toArray(".reveal").forEach(function (elem) {
        hideElement(elem);

        ScrollTrigger.create({
            trigger: elem,
            onEnter: function () {
                fadeInOnScrollFrom(elem)
            },
            onEnterBack: function () {
                fadeInOnScrollFrom(elem, -1)
            },
            onLeave: function () {
                hideElement(elem)
            },
        });
    });
}

const hideElement = (elem) => {
    gsap.set(elem, {autoAlpha: 0});
}

const setZoomClass = (image) => {
    image.classList.add("zoom")
}

const removeZoomClass = (image) => {
    image.classList.remove("zoom")
}

const zoomInImage = () => {
    gsap.utils.toArray(".zoomImage").forEach(function (image) {

        ScrollTrigger.create({
            trigger: image,
            start: '-200px 100px',
            end: '-30px 100%',
            onEnter: function () {
                setZoomClass(image)
            },
            onLeave: function () {
                removeZoomClass(image)
            },
            onEnterBack: function () {
                setZoomClass(image)
            },
        });
    });
}

const textScrollOver = () => {

   gsap.utils.toArray(".scrollOverElementWrapper").forEach((panel) => {
       const video = panel.parentElement.querySelector('video');
       const scrollOverElements = panel.querySelectorAll('.scrollOverElement');
       scrollOverElements.forEach((scrollOverElement) =>{
        //    scrollOverElement.style.height = video.clientHeight+"px";
        })
       ScrollTrigger.create({
            trigger: panel,
            start: "top 100%",
            end: "100% 99%",
            //markers: true,
            pin: ".scrollOverPinner",
            pinSpacing: false,
            toggleActions: "restart none reverse none"
        });
    });
}

const fadeContent = () => {

    let tl = gsap.timeline({
        scrollTrigger: {
            id: "trigger2",
            trigger: ".elementFadeOutTrigger2",
            scrub: true,
            pin: ".pinner2",
            start: "top 0",
            end: "300% 0",
            toggleActions: "restart none reverse none"
        }
    });
    tl.from(".fadeOutElement0", {opacity: 1, y: 500 , duration: 2})
        .from('.fadeOutElement1',{opacity: 0, y: 500, duration: 1.5})
        .from(".fadeOutElement2", {opacity: 0, y: 1000, duration: 1.5})
        .from(".fadeOutElement3", {opacity: 0, y: 1000, duration: 1})
}

const fadeImages = () => {

    let tween = gsap.fromTo(".gsap-fade-element", {autoAlpha:0}, {autoAlpha:1, stagger: 1}),
        st = ScrollTrigger.create({
            trigger: ".trigger3",
            start: "top center",
            end: "+=500",
            toggleActions: "restart none reverse none",
            animation: tween
        });
}

document.addEventListener("DOMContentLoaded", function () {
    window.onbeforeunload = function () {
        window.scrollTo(0, 0);
    };
    gsap.registerPlugin(ScrollTrigger);

    zoomInImage();
    revealFromSide();
    fadeContent();
    textScrollOver();
    fadeImages();
});
